#receipt-body {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
  background: #fff;
  direction: rtl !important;
}
#top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
#receipt-num {
  border: 1px solid #000;
  width: max-content;
  height: max-content;
  font-size: 1em;
  font-weight: bold;
}
#shop-title {
  font-size: 1.5em;
  color: #000;
  font-weight: bold;
}
#receipt-date {
  font-size: 1em;
  color: #000;
  text-align: left;
  font-weight: bold;
}

#mid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#customer-name {
  font-weight: bold;
  font-size: 1em;
}

#deliver-order {
  font-weight: bold;
  font-size: 1em;
}

#table-body {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  display: flex;
  justifycontent-content: center;
  align-content: center;
}

#table {
  width: 100%;
}

#table-head {
  font-size: 0.8em;
  color: #000;
}
.pricePaid {
  background-color: #000;
  color: rgb(255, 0, 0);
}

#table-item {
  width: 100%;
  text-align: center;
  color: #000;
}

#item-text {
  font-size: 1em;
  border: 1px solid black;
}

#discount-item {
  background-color: #fff !important;
  color: #000 !important;
}

#thanks-title {
  font-size: 1em;
  color: #000;
  text-align: right;
}

#address-title {
  font-size: 1em;
  color: #000;
}

#phone-title {
  font-size: 1em;
  color: #000;
}

.real_price {
  width: max-content;
}

#hafez-poem {
  font-size: 0.8em;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
}
#copyright {
  font-size: 0.7em;
  color: #000;
  text-align: left;
}

#CancelIcon {
  position: absolute;
  top: 50px;
  right: 100px;
  z-index: 30000;
  color: black;
  border: 1px solid white;
  background-color: white;
}
#section-to-print {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: none !important;
    padding: none !important;
  }
}
