.login-paper-div {
  padding: 1em;
  /* margin: 20px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: flex-end; */
  background-color: inherit !important;
  /* z-index: 30000 !important; */
  /* backdrop-filter: blur(10px); */
  box-shadow: none !important;
}

.otp {
  direction: ltr !important;
}

.whiteInput {
  color: #ffffff;
}

.login-paper-container {
  /* position: absolute;
  bottom: 0px !important;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%); */
  /* width: calc(100% - 60px); */
  /* max-height: 100%; */
  /* overflow: auto; */
  width: 100vw;
  /* background-color: #ffffff; */
  /* z-index: 30001; */
  /* margin-top: 40px; */
}

#countdown {
  margin-bottom: 5px;
  text-align: center;
  color: rgb(134, 134, 134);
  font-size: 1em;
}

.MuiInputLabel-formControl {
  right: 0 !important;
  left: auto !important;
  position: absolute !important;
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}

.leaflet-container {
  margin-top: 5px;
  height: 200px;
  width: 100%;
}

#blinking {
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.swal2-styled.swal2-confirm {
  background-color: #680322 !important;
}

#discount-badge {
  display: block;
  position: inherit;
}

.MuiSpeedDialAction-staticTooltipLabel {
  width: max-content;
  color: #ffffff !important;
}

#SpeedDialtooltipexample-action-0-label {
  background-color: #ff0042 !important;
}

#SpeedDialtooltipexample-action-1-label {
  background-color: #680322 !important;
}

#SpeedDialtooltipexample-action-2-label {
  background-color: #30354b !important;
}

#chips .MuiChip-avatar {
  margin-right: 6px;
}

.countdownText {
  color: #ff005c;
  border-radius: 5px;
  margin-bottom: 5px;
}

.otpContainer {
  direction: ltr !important;
}

.otpContainer :hover {
  border-color: #ffffff;
}

#categoryChip .MuiChip-avatar {
  margin-right: 5px !important;
}

#categoryChip .MuiChip-label {
  margin-right: -10px !important;
}

.guestLoginPage {
  margin-top: 200px;
}

.loginContainer {
  /* height: 100vh; */
  /* max-height: 100vh !important;
  overflow-y:auto !important; */
  height: 100%;
}

.loginGuestContainer {
  overflow: hidden;
  height: 100vh;
  background: #000000;
}

.guestContainer {
  height: 100vh;
}

.loginPhoto {
  background-image: url('../static/image/loginImage.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.guestPhoto {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carouselGuest {
  height: 100vh;
}

.carouselIndicator .Carousel-indicators-17 {
  direction: ltr;
}

.guestPhoto {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#commentNumbers {
  unicode-bidi: plaintext;
}

.otpInput {
  background-color: #000000 !important;
  color: #ffffff !important;
}
#markerBlink {
  animation: markerBlink 1s infinite ease-in-out;
  -webkit-animation: markerBlink 1s infinite ease-in-out;
}

@keyframes markerBlink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.loginLogo {
  position: absolute;
  top: 28%;
  left: 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: max-content;
  border: 1px solid #ffffff;
  border-radius: 20px !important;
  z-index: 30000 !important;
  backdrop-filter: blur(10px);
  padding: 2px;
}

.loginLogoPic {
  width: 50px;
  height: 50px;
}

.MuiBreadcrumbs-separator {
  margin: 0px 2px 0px 2px !important;
}

.makeStyles-ImageList-29 {
  padding: 0px !important;
}

#foodSearchPic {
  min-width: 0px !important;
  margin-left: 5px;
  object-fit: cover;
}

.MuiListItemAvatar-root {
  min-width: 0px !important;
}

.MuiChip-deleteIcon {
  margin: 0px !important;
  color: #800021 !important;
}

#zIndex {
  z-index: 300000 !important;
}

.page-not-found-container {
  z-index: 3000000;
  background: #ffffff;
  height: 100vh;
  position: relative;
}

.bounceAnimation {
  animation: bounce 2s ease-in-out;
  -webkit-animation: bounce 2s ease-in-out;
}

.costom-textField {
  text-align: left !important;
  direction: ltr !important;
}

.costom-textField:focus,
.costom-textField:hover,
.costom-textField:active,
.costom-textField::after,
.costom-textField::before {
  outline: 0px !important;
  -webkit-appearance: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  border: none !important;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

html {
  --white: #ffffff;
  --light_grey: #e5e5e5;
  --gray: #888888;
  --dark_grey: #6d6d6d;
  --green: #16edb1;
  --light_pink: #fffdfe;
  --pink: #f4cbda;
  --magenta: #ff005c;
  --dark_magenta: #ed1652;
}

* {
  font-family: 'Vazir';
}

@media only screen and (min-width: 460px) {
  .loginPhoto {
    background-image: url('../static/image/loginImage-md.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #ffffff;
  }
  .login-paper-div {
    /* height: 100vh; */
  }
}
@media only screen and (min-width: 800px) {
  .loginPhoto {
    background-image: url('../static/image/loginImg.png');
    background-repeat: no-repeat;
    background-size: 100% 56%;
    background-color: #ffffff;
  }
}

.explosion {
  position: absolute;
  width: 600px;
  height: 600px;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: pop 1s reverse forwards;
}

@keyframes pop {
  from {
    opacity: 0;
  }
  to {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
}
