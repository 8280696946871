
.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    direction: rtl !important;
    border-radius: 8px;
    padding: 8px;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    direction: rtl !important;
    justify-content: flex-start;
}

.carousel-content-wrapper {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    direction: rtl !important;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;
    scrollbar-width: none;
    direction: rtl !important;
}

.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content>* {
    width: 100%;
    flex-shrink: 0
}

.carousel-content.show-2>* {
    width: 50%;
    padding: 4px;
    /* width: max-content; */
}

.carousel-content.show-3>* {
    /* width: calc(80% / 3); */
    width: calc(100% / 3);
     padding: 4px;
}

.carousel-content.show-4>* {
    /* width: calc(80% / 4); */
    width: calc(100% / 4);
     padding: 4px;
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    height: fit-content;
    display: flex;
    border: none;
    background-color: transparent;
    border-radius: 50px;
}

.left-arrow {
    left: -24px;
}

.right-arrow {
    right: -24px;
}

/* @media (hover: none) and (pointer: coarse) {

    .left-arrow,
    .right-arrow {
        display: none;
    }
} */